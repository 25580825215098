import React from "react"
import Seo from "../../components/Seo/Seo"
import * as styles from  "./community.module.sass"
import { StaticImage } from "gatsby-plugin-image"
import PageTransition from "../../components/PageTransition";
import { Link } from "gatsby";

const CommunityPage = (props) => (

	<PageTransition {...props}>

		<Seo title="Interesting" keywords={[]} />

		<div className={styles.communityPage}>

			<header>

				<StaticImage src="../../images/rocketship.png" width="800" />

				<h1 id="main-title">Community</h1>
				<p className="lead-message">You are not alone in the Universe!</p>

				<div className={styles.notice}>

					<h2>Please note</h2>
					<p>The new Deviant Robot Discord community start Early April 2021 and while we get things sorted out will be invitation only. We hope to be opening it up to the public shortly.</p>

				</div>

			</header>

			<section>

				<article className={styles.Page}>

					<h2>Online Community</h2>

					<ul>
						<li><Link to="/community/discord-rules-and-guidelines">Discord community rules and guidelines</Link></li>
						<li><Link to="/community/monthly-meeting">Main Monthly Monday Meeting</Link></li>
					</ul>

					<p>The hub of our online community is the Deviant Robot Discord server. This server allows us to connect and chat with Deviants from all over the world who come together to play games, talk about common interests and discuss ideas.</p>

					<h4>What is Discord</h4>

					<p>Discord is an application that allows you to Talk, chat, hang out, and stay connected with communities collected into servers. Originally created for gamers, Discord has grow to be a powerful platform for communities of all kinds.</p>

					<h4>Get started with Discord</h4>

					<p>Discord is free and easy to use and can be found on iOS, Android, Linux, Mac and Windows. You can also run it in your web browser. Head over to https://discord.com/ and create your account.</p>

					<h3>Discord Clubs</h3>

					<h4>Book Club</h4>

					<p>Reading and discussing non-fiction books; one chapter a week.</p>

					<h4>Writing Club</h4>

					<p>For those interested in learning sharing the experience of making video game.</p>

					<h4>Deviant Minds</h4>

					<p>Discussing philosophy and ideas. Each week we will pick a video, idea, film book and discuss the ideas within.</p>

					<h4>Game Jammers</h4>

					<p>For those interested in learning sharing the experience of making video game.</p>

					<h4>Deviant Dungeons & Dragons</h4>

					<p>Regular sessions where we play online group games together such as Among Us, Cards against humanity and codenames</p>

				</article>

			</section>

		</div>

	</PageTransition>

)

export default CommunityPage
